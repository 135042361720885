import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from '@/pages/_app';
import React, { useMemo } from 'react';
import Layout from '../layouts';
import { lowerName } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { getPlanbowlSceneListData } from '@/lib/service';

const HairStyleContent = dynamic(() => import('@/components/PlanbowlHome'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl, defaultLocale } = context;

  // const localeRes = await serverSideTranslations(locale, ['common'])
  // const data = await getPlanbowlSceneListData(locale)

  const [localeRes, dataRes] = await Promise.all([
    serverSideTranslations(locale, ['common']),
    getPlanbowlSceneListData(locale),
  ]);

  const currentURL = `https://${req.headers.host}${resolvedUrl}`;
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      ...localeRes,
      dataList: dataRes?.data ?? [],
      herf: currentURL,
      title: tempData[`${lowerName}_meta_Title`],
      description: tempData[`${lowerName}_meta_Desc`],
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return useMemo(() => <HairStyleContent data={props} />, [props]);
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title ?? '';
  const desc = page?.props?.description ?? '';
  const img = `https://res-gpts.pumpsoul.com/img/share_img.webp`;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={img} />
          <meta property="og:image:secure_url" content={img} />
          <meta property="og:type" content="website" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:url" content={page.props?.herf} />
          <meta property="og:image:width" content="32" />
          <meta property="og:image:height" content="32" />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={img} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:type" content="image/png" />
          <meta name="twitter:site" content={page.props?.herf} />
          <meta name="twitter:image:width" content="32" />
          <meta name="twitter:image:height" content="32" />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
